.app-banner {
  height: $footer-phones-height;

  @include respond-to(tablets) {
    height: $footer-tablets-height;
  }

  @include respond-to(desktops) {
    display: none;
  }
}
