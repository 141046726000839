$inbox-sidebar-width: unit(75);
$inbox-border-height: unit(0.25);
$inbox-footer-height: unit(18);
$inbox-card-min-height: unit(80);

$inbox-header-height-desktop: $l-header-main-area-height + $l-header-navigation-height +
  $inbox-border-height;
$inbox-off-content-height-desktop: $inbox-header-height-desktop + $inbox-footer-height + $gutter;
$inbox-card-height-desktop: calc(100vh - #{$inbox-off-content-height-desktop});

$inbox-header-height-phone: unit(13) + (2 * $inbox-border-height);
$inbox-searchbar-height-phone: unit(13) + (2 * $inbox-border-height);
$inbox-off-content-height-phone: $inbox-header-height-phone + $inbox-searchbar-height-phone;
$inbox-card-height-phone: calc(100vh - #{$inbox-off-content-height-phone});
