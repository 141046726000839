@mixin text-field-default {
  @include font-size($input-font-size, true);

  // this is needed to override MEDIUM and HUGE sizes
  height: $input-height !important;

  border-radius: $input-border-radius !important;
}

@mixin text-field-medium {
  // we have no unified inputs markup/styles, so !important are needed :(
  height: $input-medium-height !important;
  @include font-size($input-medium-font-size, true);
  border-radius: $input-medium-border-radius !important;
}
