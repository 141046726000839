$main-footer-bg: palette(greyscale-level-6);
$main-footer-link-color: palette(greyscale-level-2);
$main-footer-bottom-padding: unit(4);
$main-footer-top-padding: unit(5);
$main-footer-link-line-height: unit(6);
$main-footer-links-padding: unit(3);
$main-social-padding: unit(3);
$main-social-media-links: unit(3) unit(5) unit(3) 0;
$main-app-store-links: unit(3) 0px unit(3) unit(5);
$main-app-store-links-phone: unit(1);
$main-links-link-item-margin: unit(3) 0px;
